import { app, auth, db, functions } from "https://framer.com/m/config-1Ws6.js"
import {
    getFirestore,
    collection,
    doc,
    setDoc,
    getDoc,
    updateDoc,
    arrayUnion,
    serverTimestamp,
    FieldValue,
} from "firebase/firestore"

const BASE_URL = "https://app.cursodeframer.com.br/aulas/"

/**
 *
 * SetLastLesson
 *
 */
type SetLastLessonProps = {
    userId: string
    courseTitle: string
    lessonTitle: string
    path: string
}

export const setLastLesson = async (props: SetLastLessonProps) => {
    const docRef = doc(db, "users", props.userId, "progress", "lastLesson")
    const data = {
        course: props.courseTitle,
        lesson: props.lessonTitle,
        date: Date.now(),
        path: BASE_URL + props.path,
    }

    const lastLessonDoc = await getDoc(docRef)
    if (!lastLessonDoc.exists()) {
        // Se o documento não existe, cria um novo com os dados fornecidos
        await setDoc(docRef, data)
    } else {
        // Se o documento já existe, atualiza os dados fornecidos
        await updateDoc(docRef, data)
    }
}

type GetLastLessonProps = {
    userId: string
}

export const getLastLesson = async (props: GetLastLessonProps) => {
    const docRef = doc(db, "users", props.userId, "progress", "lastLesson")

    const lastLessonDoc = await getDoc(docRef)

    if (lastLessonDoc.exists()) {
        return lastLessonDoc.data()
    }
}

/**
 *
 * GetUserCourseProgress
 *
 */

type GetUserCourseProgressProps = {
    userId: string
    courseSlug: string
    totalLessons: number
}

export const getUserCourseProgress = async (
    props: GetUserCourseProgressProps
) => {
    const docRef = doc(db, "users", props.userId, "progress", props.courseSlug)

    const lastLessonDoc = await getDoc(docRef)

    console.log(lastLessonDoc.data())
}

/**
 *
 * UpdateProgressLesson
 *
 */

type UpdateProgressLessonProps = {
    userId: string
    courseSlug: string
    lessonSlug: string
}

type EventProps = "started" | "completed"

export const updateProgressLesson = async (
    props: UpdateProgressLessonProps,
    event: EventProps
) => {
    const docRef = doc(db, "users", props.userId, "progress", props.courseSlug)

    const lessonRef = `${props.lessonSlug}.${
        event === "started" ? "startedAt" : "completedAt"
    }`

    const lastLessonDoc = await getDoc(docRef, { source: "cache" })

    console.log(lastLessonDoc)

    if (!lastLessonDoc.exists() || !lastLessonDoc.data()[props.lessonSlug]) {
        // Se o documento ou a lição não existem, cria um novo com os dados fornecidos
        await setDoc(docRef, {
            [props.lessonSlug]: {
                [event === "started" ? "startedAt" : "completedAt"]: Date.now(),
            },
        })
    } else if (
        !lastLessonDoc.data()[props.lessonSlug][
            event === "started" ? "startedAt" : "completedAt"
        ]
    ) {
        // Se o documento já existe e a lição já foi iniciada ou concluída, atualiza os dados fornecidos
        await updateDoc(docRef, {
            [lessonRef]: Date.now(),
        })
    }
}
